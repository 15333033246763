import clsx from 'clsx';
import {useEffect, useState} from 'react';

import LogoIcon from '~/components/icons/Logo';
import {Link} from '~/components/Link';

export default function HeaderBackground() {
  const [scrolledDown, setScrolledDown] = useState(false);

  const handleScroll = () => {
    setScrolledDown(window.scrollY > 100);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    // Trigger handler on mount to account for reloads
    handleScroll();
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  return (
    <div className="stabBg absolute inset-0">
      {/* Background */}
      <div
        className={clsx([
          'stabBg absolute inset-0 bg-opacity-90 backdrop-blur-lg backdrop-filter duration-500',
          scrolledDown ? 'opacity-100' : 'opacity-0',
        ])}
      />

      {/* Logo */}
      <Link to="/">
        <div
          className={clsx(
            'absolute left-1/2 top-4 flex w-[90px] -translate-x-1/2 items-center',
            'lg:w-[160px]',
          )}
        >
          <div
            className={clsx([
              'stabLogo duration-500',
              scrolledDown ? 'logoA' : 'logoB',
            ])}
          >
            <LogoIcon />
          </div>
        </div>
      </Link>
    </div>
  );
}
