import clsx from 'clsx';
import {useCallback} from 'react';

import CollectionGroup from '~/components/global/collectionGroup/CollectionGroup';
import {Link} from '~/components/Link';
import type {SanityMenuLink} from '~/lib/sanity';

import {useMatches} from '@remix-run/react';
import {DEFAULT_LOCALE} from '~/lib/utils';

/**
 * A component that defines the navigation for a web storefront
 */

type Props = {
  menuLinks: SanityMenuLink[];
};

export default function Navigation({menuLinks}: Props) {
  const [root] = useMatches();
  const selectedLocale = root.data?.selectedLocale ?? DEFAULT_LOCALE;

  const lang = selectedLocale.language;

  const renderLinks = useCallback(() => {
    return menuLinks?.map((link) => {
      let checkedLink = '';
      checkedLink = link.title;

      if (checkedLink === 'Sneaker' && lang === 'DE') {
        checkedLink = 'Schuhe';
      }

      if (checkedLink === 'Apparel' && lang === 'DE') {
        checkedLink = 'Bekleidung';
      }

      if (link._type === 'collectionGroup') {
        return <CollectionGroup collectionGroup={link} key={link._key} />;
      }
      if (link._type === 'linkExternal') {
        return (
          <div className="flex items-center" key={link._key}>
            <a
              className="linkTextNavigation"
              href={link.url}
              rel="noreferrer"
              target={link.newWindow ? '_blank' : '_self'}
            >
              {link.title}
            </a>
          </div>
        );
      }
      if (link._type === 'linkInternal') {
        if (!link.slug) {
          return null;
        }

        let target = link.slug;
        if (checkedLink === 'Blog' && lang === 'DE') {
          target = '/pages/blog-de';
        }
        if (checkedLink === 'Blog' && lang === 'EN') {
          target = '/pages/blog-en';
        }

        return (
          <div className="flex items-center" key={link._key}>
            <Link className="linkTextNavigation" to={target}>
              {checkedLink}
            </Link>
          </div>
        );
      }

      return null;
    });
  }, [menuLinks]);

  return (
    <nav
      className={clsx(
        'relative top-3 hidden items-stretch justify-start gap-6 text-md',
        'lg:flex',
      )}
    >
      {renderLinks()}
    </nav>
  );
}
